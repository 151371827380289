import React from 'react';
import { useRouter } from 'next/router';
import LoginSection from '../components/Login/LoginSection';
import { withAuth } from '../helpers/auth';
import { GetServerSidePropsContext } from 'next';

const Login = () => {
  const router = useRouter();
  return (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <div className="w-full max-w-2xl">
        <LoginSection
          onLogin={() => {
            router.push('/');
          }}
        />
      </div>
    </div>
  );
};

export async function getServerSideProps(ctx: GetServerSidePropsContext) {
  try {
    await withAuth(ctx);
    return {
      redirect: {
        destination: '/',
        permanent: false,
      },
    };
  } catch (err) {
    return {
      props: {},
    };
  }
}

export default Login;
